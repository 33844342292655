<template>
  <div class="list__content-switching">
    <div class="list-tabbar">
      <div class="list-tabbar__links">
        <a
          v-for="(item, index) in tabs"
          class="list-tabbar__link"
          :key="index"
          :class="item.value === tabs[tab].value ? 'active' : ''"
          @click="tab = index"
          >{{ item.text }}</a
        >
      </div>
      <img
        src="/img/images/list/list__bg.png"
        alt="list__bg"
        class="list__bg"
      />
    </div>
    <div class="keep ">
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in tabs" :key="item.value">
          <slot :name="item.value"> {{ item.text }} </slot>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoLayout",
  props: {
    tabs: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      tab: 0
    };
  }
};
</script>

<style scoped></style>
