<template>
  <v-dialog v-model="dialog" persistent max-width="420">
    <template v-slot:activator="{ on, attrs }">
      <button v-bind="attrs" v-on="on" class="btn btn-delete btn_color_red">
        удалить {{ name }}
      </button>
    </template>
    <v-card>
      <v-card-title v-if="title" class="delete-title delete-center">
        <div>Удалить</div>
        <span class="delete-bold"> "{{ title }}" </span>
      </v-card-title>
      <v-card-text
        v-if="isDescription"
        class="delete-center"
        :class="{ 'pt-8': !title }"
        style="white-space: pre-wrap;"
      >
        {{ description }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          width="154"
          height="42"
          style=""
          class="ma-5 btn btn_color_white"
          text
          @click="close"
        >
          Отменить
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          width="154"
          height="42"
          color="#EB5C6D"
          class="ma-5 btn btn_color_red"
          style="color:white"
          @click="realDelete"
        >
          Удалить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeletePopup",
  data() {
    return {
      dialog: false
    };
  },
  props: {
    name: {
      type: [String, Number]
    },
    title: {
      type: String
    },
    deleteFunction: {
      type: Function
    },
    isDescription: {
      type: Boolean,
      default: true
    },
    description: {
      type: String,
      default:
        "В случае ошибочного удаления вы можете восстановить его в течении 24 часов"
    }
  },
  methods: {
    close() {
      this.dialog = false;
    },
    realDelete() {
      this.deleteFunction();
      this.close();
    }
  }
};
</script>

<style scoped>
.delete-title {
  padding-top: 30px !important;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px !important;
  /* or 143% */

  text-align: center !important;

  color: #515a6e;
}
.delete-bold {
  font-weight: 700 !important;
}

.delete-center {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 12px;
}
</style>
