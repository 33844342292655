import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";
import _ from "lodash";
import phoneFilter from "@/helpers/filters/phoneFilter";

const tableColsUsers = [
  {
    text: "ID",
    value: "id",
    userRole: [30, 40, 50, 60, 70],
    width: 25,
    sort: true
  },
  {
    text: "ФИО",
    userRole: [70],
    renderFunction: value =>
      `${value.last_name} ${value.first_name} ${
        value.middle_name ? value.middle_name : "_"
      } `,
    value: "last_name",
    sort: true
  },
  {
    text: "Телефон",
    value: "phone_number",
    renderFunction: value => phoneFilter.execute(value?.phone_number),
    userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Пользователь",
    userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
    renderFunction: value => {
      return value?.additional_fields.length != 0
        ? value?.additional_fields[0].value
        : "-";
    },
    value: "user"
  },
  {
    text: "Комментарий",
    userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
    renderFunction: value => {
      return value?.additional_fields.length != 0
        ? value?.additional_fields[1].value
        : "-";
    },
    value: "comment"
  },

  {
    text: "Дата создания",
    userRole: [30, 40, 50, 60, 70],
    renderFunction: value => {
      return moment(value.created_at * 1000).format("DD.MM.YYYY");
    },
    value: "created_at",
    sort: true
  },
  {
    text: "Регистрация",
    value: "is_phone_confirmed",
    renderFunction: value =>
      `<p style='
        ${
          value.is_phone_confirmed === true
            ? "color: #95C23D!important;"
            : "color: #EB5C6D!important;"
        }'
      >${
        value.is_phone_confirmed === true && value.registered_at != null
          ? moment(value.registered_at * 1000).format("DD.MM.YYYY")
          : "Нет"
      }</p>`,
    userRole: [30, 40, 50, 60, 70],
    sort: true
  },

  {
    text: "ПО",
    value: "push_device_type",
    renderFunction: value =>
      value.is_phone_confirmed
        ? dictionariesHelper.userTypes.pushDeviceType[value.push_device_type]
        : "-",
    userRole: [70]
  },

  {
    text: "Тестовый период",
    value: "free_trial",
    renderFunction: value =>
      `<p style='
        ${
          value.free_trial === true
            ? "color: #95C23D !important"
            : "color: #EB5C6D !important"
        }'
      >${value.free_trial === true ? "Доступен" : "Использован"}</p>`,
    userRole: [30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Доступные группы пользователей",
    value: "access_groups",
    renderFunction: value => {
      const access = value.access_groups;
      if (access && access.length > 0) {
        return access.reduce((res, access) => {
          res += access.title + "<br/>";
          return res;
        }, "");
      } else {
        return "-";
      }
    },
    userRole: [30, 60, 70]
  },
  {
    text: "Сумма всех платежей",
    value: "payments_total",
    userRole: [30, 60, 70]
  },

  {
    text: "Объектов",
    value: "objects_count",
    userRole: [60, 70],
    src: "/img/table_icons/tabbar__icon_3.svg",
    width: 20
  },
  {
    text: "Компаний",
    value: "organizations_count",
    userRole: [60, 70],
    src: "/img/table_icons/tabbar__icon_2.svg",
    width: 20
  },
  {
    text: "Дилеров",
    value: "dillers_count",
    userRole: [70],
    src: "/img/table_icons/tabbar__icon_1.svg",
    width: 20
  },

  {
    text: "Выбранная группа пользователей",
    value: "actual_accesses",
    renderFunction: value => {
      const access = value.actual_accesses; // выбранные группы
      if (access && access.length > 0) {
        const filter = _.orderBy(access, ["id"], ["desc"]);
        return filter[0]?.group_title || "-";
      } else {
        return "-";
      }
    },
    userRole: [21, 31]
  },
  {
    text: "Карта",
    value: "card_code",
    renderFunction: value => {
      return value.cards.length > 0
        ? value.cards.reduce((accum, item) => {
            accum += `<div style="display:relative"><span> ${
              item.code
            }</span><br/><span style="${
              item.status == "issued"
                ? "color: #95C23D!important;"
                : "color: #EB5C6D!important;"
            }"> ${item.status_display}</span></div>`;
            return accum ? `<div style="display:relative">${accum}</div>` : "-";
          }, "")
        : value.card_is_awaited
        ? `<div style="display:relative">
           <span">Требуется ввод карты</span></div>`
        : `<div style="display:relative">
           <span style="color: #EB5C6D!important;">Не оплачено</span></div>`;
    },
    userRole: [60, 70]
  }
];
export default tableColsUsers;
