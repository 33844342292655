<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries">
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      disable-router
      :table-data="tableData"
      :header="header"
      :source-object="sourceObject"
    />
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsKindergartensPayment from "@/helpers/tableCols/paymentKindergardens";
export default {
  name: "KindergartensPayments",
  data() {
    return {
      loadingBtn: false,
      // type: "default",
      queries: query.paymentsObject.filter(
        el =>
          el.type !== "diller" &&
          el.type !== "organization" &&
          el.type !== "object" &&
          el.type !== "object-type" &&
          el.objType !== "generall" &&
          el.objType !== "object"
      )
    };
  },
  components: { TableFilters, TableLayout },
  computed: {
    header() {
      return tableColsKindergartensPayment.filter(
        el =>
          el.userRole.includes(this.$store.getters.getCurrentRole) &&
          el.value != "diller_name" &&
          el.text != "Компания" &&
          el.text != "Объект" &&
          el.text != "ФИО" &&
          el.text != "Тип объекта"
      );
    },
    tableData() {
      return this.$store.getters.getOrganizationPayments.results;
    },
    sourceObject() {
      return this.$store.getters.getOrganizationPayments;
    },
    urlQuery() {
      return {
        id: this.$route.params.id,
        query: this.$route.query
      };
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store.dispatch("downloadPaymentsList", this.header).then(() => {
        this.loadingBtn = false;
      });
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: {
        ...this.urlQuery.query,
        organization: this.urlQuery.id,
        status__in: "completed,awa-card"
        // type: this.type
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchKindergartensPaymentsList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            organization: this.urlQuery.id,
            status__in: "completed,awa-card"
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchKindergartensPaymentsList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  }
  // beforeRouteLeave(to, from, next) {
  //   this.$store.dispatch("clearPaymentsPage").then(() => next());
  // }
};
</script>

<style scoped></style>
