<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries">
      <template v-slot:filter-title>
        <span v-if="sourceObject.count">
          <span class="title-filter">Всего:</span>{{ sourceObject.count }}
          <!--          <span>пользователей</span>-->
        </span>
      </template>
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
      <!--      <template v-slot:add-button>-->
      <!--        <router-link to="createUser" append class="btn btn-add btn_color_green"-->
      <!--          >Добавить пользователя1-->
      <!--        </router-link>-->
      <!--      </template>-->
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      to="users"
      :table-data="sourceObject.results"
      :header="header"
      :source-object="sourceObject"
    />
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsUsers from "@/helpers/tableCols/User";
import _ from "lodash";
import moment from "moment";

export default {
  name: "KindergartensUsers",
  data() {
    return {
      loadingBtn: false,
      queries: query.usersKindergarten,
      objectId: null // id объекта ДС
      // type: "default",
    };
  },
  components: { TableFilters, TableLayout },
  computed: {
    header() {
      let preHeader = tableColsUsers.filter(el =>
        [
          "id",
          "phone_number",
          "app_paid_until",
          "is_active",
          "access_groups",
          "total_payments",
          "comment",
          "user"
        ].includes(el.value)
      );

      preHeader.$insert(1, {
        text: "Регистрация",
        value: "is_phone_confirmed",
        renderFunction: value =>
          `<p style='
        ${
          value.is_phone_confirmed === true
            ? "color: #95C23D!important;"
            : "color: #EB5C6D!important;"
        }'
      >${
        value.is_phone_confirmed === true && value.registered_at != null
          ? moment(value.registered_at * 1000).format("DD.MM.YYYY")
          : "Нет"
      }</p>`,
        userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
        sort: true
      });

      // preHeader.$insert(4, {
      //   text: "Карта",
      //   value: "card",
      //   renderFunction: value => {
      //     const сardsObj = value.cards.filter(el => el.object == this.objectId); //массив карт принадлеж. только данному ДС
      //
      //     return сardsObj.length > 0
      //       ? сardsObj.reduce((accum, item) => {
      //           accum += `<div style="display:relative"><span>${item.card}</span></div>`;
      //           return accum
      //             ? `<div style="display:relative">${accum}</div>`
      //             : "-";
      //         }, "")
      //       : value.card_is_awaited
      //       ? `<div style="display:relative"><span">Требуется ввод карты</span></div>`
      //       : `<div style="display:relative"><span style="color: #EB5C6D!important;">Не оплачено</span></div>`;
      //   },
      //   userRole: [21, 31, 60, 70]
      // });

      preHeader.$insert(5, {
        text: "Карта",
        value: "card",
        renderFunction: value => {
          return value.card != null
            ? ` <span>${
                value.card.code
                  ? value?.card.code
                  : value.card.is_awaited
                  ? "Требуется ввод карты "
                  : "Не оплачено"
              }</span>`
            : `<div style="display:relative"><span style="color: #EB5C6D!important;">Не оплачено</span></div>`;
        },
        userRole: [21, 31, 60, 70]
      });

      preHeader.$insert(6, {
        text: "Приложение",
        renderFunction: value => {
          let access = value.actual_accesses; // выбранные группы
          if (access.length >= 2) {
            for (let i = 0; i < access.length; i++) {
              if (access[i].access_type == "app") {
                access = [access[i]];
              }
            }
          }
          if (access && access.length > 0) {
            return access.reduce((res, access) => {
              if (access.access_by == "paid" && access.access_type == "app") {
                res += `<div  style="color: #95C23D!important;">${moment(
                  access.date_end * 1000
                ).format("DD.MM.YYYY")}</div>`;
                return String(res);
              } else if (
                access.access_by == "trial" &&
                access.access_type == "app"
              ) {
                res += `<div style="color: #95C23D!important;">Пробный, ${moment(
                  access.date_end * 1000
                ).format("DD.MM.YYYY")}</div>`;
                return res;
              } else if (
                access.access_by == "unpaid" &&
                access.access_type == "app"
              ) {
                res += `<div style="color: #95C23D!important;">Бесплатный доступ</div>`;
                return res;
              } else {
                return `<div><span style="color: #EB5C6D!important">Не оплачено</span></div>`;
              }
            }, "");
          } else {
            return `<div><span style="color: #EB5C6D!important">Не оплачено</span></div>`;
          }
        },
        userRole: [21, 31, 60, 70]
      });

      preHeader.$insert(7, {
        text: "Выбранная группа пользователей",
        value: "actual_accesses",
        renderFunction: value => {
          const access = value.actual_accesses; // выбранные группы
          const access_groups = value.access_groups; // все группы в которые входит пользователь
          if (access && access.length > 0) {
            let filter = _.intersectionWith(
              access,
              access_groups,
              (x, y) => x?.group_title == y.title
            );
            filter = _.orderBy(filter, ["id"], ["desc"]);
            return filter[0]?.group_title || "-";
            // return filter.reduce((res, access) => {
            //   res += access.group_title + "<br/>";
            //   return res;
            // }, "");
          } else {
            return "-";
          }
        },
        userRole: [30, 60, 70]
      });

      preHeader.$insert(14, {
        text: "Тестовый период",
        value: "free_trial",
        renderFunction: value =>
          `<p style='
            ${
              value.free_trial === true
                ? "color: #95C23D !important"
                : "color: #EB5C6D !important"
            }'
          >${value.free_trial === true ? "Доступен" : "Использован"}</p>`,
        userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
        sort: true
      });

      preHeader.filter(el => {
        return el.userRole.includes(this.$store.getters.getCurrentRole);
      });
      return preHeader;
    },

    sourceObject() {
      return this.$store.getters.getOrganizationUsersList;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadKindergartensAllUsersList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: {
        ...this.urlQuery.query,
        groups__organization: this.urlQuery.id
      },
      id: this.urlQuery.id
    });
    this.isDateLoaded = true;

    this.$store
      .dispatch("fetchOrganizationObjectsList") // вызываем чтобы получить id объекта ДС // TODO если удалили объект ДС то ошибка будет
      .then(res => {
        const orgName = this.$store.getters.getDealerInfo.results.name;
        this.objectId =
          res.results.filter(el => el.name == orgName)[0]?.id ||
          res.results[0]?.id ||
          null;
      })
      .then(() => {
        this.$store.dispatch("fetchKindergartensUsersList").finally(() => {
          this.isDateLoaded = false;
        });
      });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query
            // type: this.type
          }
        });

        this.isDateLoaded = true;
        this.$store.dispatch("fetchKindergartensUsersList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  }
  // beforeRouteLeave(to, from, next) {
  //   this.$store.dispatch("clearUsersPage").then(() => next());
  // }
};
</script>

<style scoped></style>
