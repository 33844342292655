import moment from "moment";
import phoneFilter from "@/helpers/filters/phoneFilter";

const tableColsCards = [
  {
    text: "ID",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],
    value: "id",
    sort: true,
    width: "70"
  },
  {
    text: "Номер",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],

    value: "code",

    sort: true
  },
  {
    text: "Статус",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],

    renderFunction: value => {
      if (value.status_display === "Не активирована") {
        let res = `<div style="color:#EB5C6D!important">Не активирована ${
          value.payment_id
            ? '<span style="color:#767d92!important; font-size: 16px">✓</span>'
            : ""
        }</div>`;
        return res;
      } else if (value.status_display === "Утеряна") {
        let res = `<div style="color:#ff9b46!important">${
          value.status_display
        } ${
          value.payment_id
            ? '<span style="color:#767d92!important;font-size: 16px">✓</span>'
            : ""
        }</div>`;
        return res;
      } else if (value.status_display === "Выдана") {
        let res = `<div style="color:#95C23D!important">${
          value.status_display
        } ${
          value.payment_id
            ? '<span style="color:#767d92!important;font-size: 16px">✓</span>'
            : ""
        } </div>`;
        // res +=  `${value.payment_id ? value.payment_id : ""}`
        return res;
      } else {
        let res = `<div style="color:#e3cc00!important">${
          value.status_display
        } ${
          value.payment_id
            ? '<span style="color:#767d92!important;font-size: 16px">✓</span>'
            : ""
        }</div>`;
        return res;
      }
    },
    value: "status",
    sort: true
  },
  {
    text: "№ оплаты",
    userRole: [10, 20],

    value: "payment_id",
    renderFunction: value =>
      value.payment_id ? value.payment_id : "Отсутствует"
  },
  {
    text: "Дата создания",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],

    // value: "created_at"
    renderFunction: value =>
      `${moment(value.created_at * 1000).format("DD.MM.YYYY")}`
  },
  {
    text: "Активировать до",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],
    value: "activation_period_expires",
    renderFunction: value =>
      `${
        value.activate_until && moment(value.activate_until * 1000).isValid()
          ? moment(value.activate_until * 1000).format("DD.MM.YYYY")
          : "-"
      }`,
    sort: true
  },
  // {
  //   text: "ФИО",
  //   userRole: [10, 20, 30, 31, 40, 50, 60, 70],
  //   value: "user",
  //   src: "/img/table_icons/tabbar__icon_9.svg",
  //   width: 20,
  //   renderFunction: value => {
  //     return (
  //       (value?.user_details?.full_name || "-") +
  //       (value.phone_number != null
  //         ? ", " + phoneFilter.execute(value?.phone_number)
  //         : "")
  //     );
  //   }
  //   // sort: true
  // },
  {
    text: "Пользователь",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],
    src: "/img/table_icons/tabbar__icon_9.svg",
    width: 20,
    renderFunction: value => {
      return value?.user?.additional_fields[0]?.value
        ? value?.user?.additional_fields[0]?.value +
            (value?.user?.phone_number != null
              ? ", " + phoneFilter.execute(value?.user?.phone_number)
              : "")
        : "-";
    }
  },
  // {
  //   text: "Комментарий",
  //   userRole: [10, 20, 30, 31, 40, 50, 60, 70],
  //
  //   renderFunction: value => {
  //     console.log(value?.user)
  //     return value?.user ? value?.user?.additional_fields[1].value : "-";
  //   }
  // },
  {
    text: "Комментарий",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],

    renderFunction: value => {
      return value?.user?.additional_fields[1]?.value
        ? value?.user?.additional_fields[1]?.value
        : "-";
    }
  },
  // {
  //   text: "Опекуны",
  //   renderFunction: value => {
  //     return value?.user
  //       ?.map(el => {
  //         if (el.additional_fields) {
  //           return (
  //             el.additional_fields[1].value
  //           );
  //         }
  //       })
  //       ?.join("\n");
  //   }
  // },
  {
    text: "Телефон",
    value: "phone_number",
    userRole: [70]
  },
  {
    text: "Группа пользователей",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],
    value: "group",

    renderFunction: value => value?.group_details?.title,
    src: "/img/table_icons/tabbar__icon_8.svg",
    width: 20
  },
  {
    text: "Детский сад",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    value: "organization_name",
    src: "/img/table_icons/tabbar__icon_4.svg",
    width: 20
  },
  {
    text: "Дата активации",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],
    value: "activated_at",
    renderFunction: value => {
      const actDate = moment(value.activated_at * 1000);
      return value.activated_at && actDate.isValid()
        ? actDate.format("DD.MM.YYYY")
        : "-";
    }
  },
  {
    text: "Дата окончания срока действия",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],

    renderFunction: value =>
      `${
        value.active_until && moment(value.active_until * 1000).isValid()
          ? moment(value.active_until * 1000).format("DD.MM.YYYY")
          : "-"
      }`
  },
  {
    text: "Срок  действия",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],
    renderFunction: value =>
      value.validity && value.validity == 1200
        ? "Бессрочно"
        : value.validity
        ? value.validity + " мес."
        : "-"
  },
  {
    text: "Дилер",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],
    src: "/img/table_icons/tabbar__icon_1.svg",
    width: 20,
    sort: true,
    // value: "type"
    renderFunction: value => value.diller
  }
];

export default tableColsCards;
