import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";
import phoneFilter from "@/helpers/filters/phoneFilter";
import { getAdditionalFields } from "@/helpers/Constants/additional_fields";

const tableColsPassageLog = [
  {
    text: "Дата",
    value: "moment",
    renderFunction: value => moment(value?.moment * 1000).format("DD.MM.YYYY"),
    sort: true
  },
  {
    text: "Время",
    value: "time",
    renderFunction: value => moment(value?.moment * 1000).format("HH:mm:ss")
  },
  {
    text: "Тип доступа",
    value: "by_card",
    renderFunction: value =>
      value?.by_card
        ? `Карта, ${value?.card_code}`
        : value?.direction_translate === "Свободные руки"
        ? "Свободные руки"
        : "Приложение",
    sort: true
  },

  {
    text: "ФИО",
    value: "user",
    renderFunction: value => value?.user_details?.full_name,
    sort: true
  },
  {
    text: "Пользователь",
    userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
    renderFunction: value => {
      return getAdditionalFields(
        value?.user_details?.additional_fields,
        "Пользователь"
      );
    },
    value: "user"
  },
  {
    text: "Комментарий",
    userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
    renderFunction: value => {
      return getAdditionalFields(
        value?.user_details?.additional_fields,
        "Комментарий"
      );
    },
    value: "comment"
  },
  {
    text: "Телефон",
    // value: "teachers"
    renderFunction: value => {
      return phoneFilter.execute(value.user_details?.phone_number);
    }
  },
  {
    text: "Группа пользователей",
    value: "data__access_group_title",
    renderFunction: value => value.data?.access_group_title || "",
    sort: true
  },

  {
    text: "Название контроллера",
    value: "controller_details",
    renderFunction: value => value?.controller_details?.name
  },
  {
    text: "ID контроллера",
    value: "controller",
    renderFunction: value => value?.controller_details?.id,
    sort: true
  },
  {
    text: "Тип контроллера",
    value: "controllersType",
    renderFunction: value =>
      dictionariesHelper.controllersType[value?.controller_details?.type]
  }
];

export default tableColsPassageLog;
