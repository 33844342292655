import moment from "moment";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import phoneFilter from "@/helpers/filters/phoneFilter";

const tableColsControllers = [
  {
    text: "ID",
    value: "id",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    width: 25,
    sort: true
  },
  {
    text: "Доступ в сеть/был в сети",
    value: "connected",
    renderFunction: value =>
      `<div style="display: flex; flex-direction: column"><span style="padding: 0 15px;background-color: ${
        value.connected
          ? "#95C23D"
          : value.last_time_online
          ? "#EB5C6D"
          : "#D6D9E1"
      }; color: #ffffff; border-radius: 10px">${
        value.connected
          ? "В сети"
          : value.last_time_online
          ? "Не в сети"
          : "Не настроен"
      }</span> <span class="mt-1">${
        !value.connected && value.last_time_online
          ? moment(value.last_time_online * 1000).format("DD.MM.YYYY в HH:mm")
          : ""
      }</span></div> `,
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Название",
    value: "name",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Тип контроллера",
    value: "type",
    renderFunction: value =>
      `${dictionariesHelper.controllersType[value.type]}`,
    userRole: [10, 20, 30, 40, 50, 60, 70]
    // sort: true
  },
  {
    text: "MAC-адрес",
    value: "type",
    renderFunction: value => `${value.primary_mac ? value.primary_mac : "-"}`,
    userRole: [10, 20, 30, 40, 50, 60, 70]
    // sort: true
  },
  {
    text: "ESP",
    value: "primary_firmware_version",
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Версия чипа ESP",
    value: "primary_firmware_board_version",
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "NRF",
    value: "secondary_firmware_version",
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Версия чипа NRF",
    value: "secondary_firmware_board_version",
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Статус",
    value: "is_active",
    renderFunction: value =>
      `<span style="color: ${value.is_active ? "#95C23D" : "#EB5C6D"}">${
        value.is_active ? "Активирован" : "Не активирован"
      }</span>`,
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },

  {
    text: "Адрес",
    value: "object_address",
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Объект",
    value: "object_name",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    src: "/img/table_icons/tabbar__icon_3.svg",
    width: 20,
    sort: true
  },
  {
    text: "Техник",
    renderFunction: value =>
      `${
        value.technician
          ? value.technician.full_name +
            ", " +
            phoneFilter.execute(value?.technician?.phone_number)
          : "-"
      }`,
    userRole: [50]
  },
  {
    text: "Компания",
    value: "organization_name",
    userRole: [60, 70, 50],
    src: "/img/table_icons/tabbar__icon_2.svg",
    width: 20,
    sort: true
  },
  {
    text: "Дилер",
    // value: "organization_diller",
    renderFunction: value =>
      `${value.organization_diller ? value.organization_diller.name : "-"}`,
    userRole: [70],
    src: "/img/table_icons/tabbar__icon_1.svg",
    width: 20,
    sort: true
  },
  {
    text: "Пользователей",
    value: "users_count",
    userRole: [10, 20, 30, 50, 60, 70],
    src: "/img/table_icons/tabbar__icon_9.svg",
    width: 20,
    sort: true
  },
  {
    text: "Групп пользователей",
    value: "access_group_count",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    src: "/img/table_icons/tabbar__icon_8.svg",
    width: 20,
    sort: true
  },
  {
    text: "Дата создания",
    userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
    renderFunction: value => {
      return moment(value.created_at * 1000).format("DD.MM.YYYY HH:mm");
    },
    value: "created_at",
    sort: true
  }
];

export default tableColsControllers;
