const tableColsUserGroup = [
  {
    text: "ID",
    value: "id",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],
    width: 25,
    sort: true
  },
  {
    text: "Название",
    value: "title",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Тариф",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],
    value: "tariff_name"
    // renderFunction: value => {
    //   return value.cardprices_details.reduce((previousValue, item) => {
    //     previousValue += ` ${item.price}`;
    //     return previousValue;
    //   }, "");
    // }
  },

  {
    userRole: [10, 20, 30, 40, 50, 60, 70],
    text: "Стоимость",
    value: "price",
    renderFunction: value => {
      return value.appprices_details &&
        value?.appprices_details?.[0]?.duration == 3 &&
        value?.cardprices_details?.[0]
        ? `<div style="min-width: 250px"> Приложение:
  ${value?.appprices_details?.[0]?.duration} мес.=${value?.appprices_details?.[0]?.cost_with_discount}₽ | ${value?.appprices_details?.[1]?.duration} мес.=${value?.appprices_details?.[1]?.cost_with_discount}₽
  Карта:
  ${value?.cardprices_details?.[0]?.duration} мес.=${value?.cardprices_details?.[0]?.price}₽ 
            </div>`
        : value?.appprices_details?.[0]?.duration == 3
        ? `<div style="min-width: 250px"> ${value?.appprices_details?.[0]?.duration} мес.=${value?.appprices_details?.[0]?.cost_with_discount}₽ | ${value?.appprices_details?.[1]?.duration} мес.=${value?.appprices_details?.[1]?.cost_with_discount}₽
            </div>`
        : value?.cardprices_details?.[0]?.price
        ? `<div style="min-width: 250px">Приложение:</br> Бесплатно </br> Карта: </br>Бессрочно=${value?.cardprices_details?.[0]?.price}₽</div>`
        : `<div style="min-width: 250px">Приложение: Бесплатно</div>`;
    }
  },
  {
    text: "Контроллеры",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],
    value: "controllers",
    src: "/img/table_icons/tabbar__icon_5.svg",
    width: 20,
    renderFunction: value => {
      return value.controllers.length > 0
        ? value.controllers_details.map(el => el.name)?.join("\n")
        : "-";
    }
  },
  {
    text: "Пользователи",
    value: "user_count",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70],
    src: "/img/table_icons/tabbar__icon_9.svg",
    width: 20
  },
  {
    text: "Объект",
    value: "object",
    renderFunction: value => `${value?.object_details?.name}`,
    src: "/img/table_icons/tabbar__icon_3.svg",
    userRole: [10, 20, 30, 31, 40, 50, 60, 70]
    // sort: true
  },
  {
    text: "Компания",
    renderFunction: value => value?.organizations_details?.name,
    src: "/img/table_icons/tabbar__icon_2.svg",
    userRole: [60, 70]
  },
  {
    text: "Дилер",
    renderFunction: value => `${value.diller ? value.diller.name : ""}`,
    src: "/img/table_icons/tabbar__icon_1.svg",
    userRole: [70]
  },
  {
    text: "Город",
    value: "city",
    userRole: [70]
  }
];
export default tableColsUserGroup;
