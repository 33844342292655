<template>
  <MainLayout>
    <template v-slot:block>
      {{
        sourceObject.results
          ? sourceObject.results.is_active === false
            ? "Заблокирован -"
            : ""
          : ""
      }}
    </template>
    <template v-slot:navbar-title>{{
      sourceObject.results ? sourceObject.results.name : ""
    }}</template>
    <template v-slot:navbar-btn-wrap>
      <BlockPopup
        v-if="sourceObject.results && sourceObject.results.is_active"
        name="Детский сад"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :block-function="blockKindergartens"
      />
      <UnBlockPopup
        v-if="!(sourceObject.results && sourceObject.results.is_active)"
        name="Детский сад"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :un-block-function="unBlockKindergartens"
      />
      <DeletePopup
        name="Детский сад"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :delete-function="deleteCompany"
      />
      <router-link
        v-if="urlQuery && urlQuery.id"
        :to="`/kindergartenEdit/` + urlQuery.id"
        class="btn btn-edit btn_color_grey"
        >редактировать</router-link
      >
      <!--      <button class="btn btn-edit btn_color_grey">редактировать</button>-->
    </template>
    <SlideLayout :tabs="slideTabs">
      <template v-slot:info>
        <InfoLayout :tabs="infoTabs">
          <template v-slot:recv>
            <div class="keep__title">
              <p class="keep__text">Детский сад</p>
              <!--              <button class="btn btn_color_grey btn-edit">Редактировать</button>-->
            </div>
            <BasicTableWithoutHeader
              :table-data="getDataKindergartensRequisites"
              v-if="sourceObject.results"
            >
            </BasicTableWithoutHeader>
            <template>
              <div class="keep__title">
                <p class="keep__text">Реквизиты</p>
                <img
                  src="img/icons/object__icon-dd.svg"
                  alt=""
                  v-if="!showRequisites"
                  style="width: 30px; height: 30px "
                  class="mr-6"
                  @click="showRequisites = !showRequisites"
                />
                <img
                  src="img/icons/object__icon-dd-hide.svg"
                  alt=""
                  v-else
                  style="width: 30px; height: 30px "
                  class="mr-6"
                  @click="showRequisites = !showRequisites"
                />
              </div>
            </template>
            <BasicTableGeneralInfo
              :table-data="getDataRequisites"
              v-if="sourceObject.results && showRequisites"
            >
            </BasicTableGeneralInfo>
          </template>
          <template v-slot:admins>
            <KindergartensPersonnel />
          </template>
        </InfoLayout>
      </template>
      <template v-slot:controllers>
        <KindergartensControllers />
      </template>

      <template v-slot:physical_cards>
        <KindergartensCards />
      </template>
      <template v-slot:childrens_groups><ChildrenGroup /></template>
      <template v-slot:childrens><KindergartensChildren /> </template>
      <template v-slot:users_group>
        <KindergartensUsersGroup />
      </template>
      <template v-slot:users>
        <KindergartensUsers />
      </template>
      <template v-slot:pass_log><KindergartensPassageLog /> </template>
      <template v-slot:payments>
        <KindergartensPayments />
      </template>
    </SlideLayout>
  </MainLayout>
</template>

<script>
import InfoLayout from "@/components/MoreInfo/InfoLayout";
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import * as query from "@/helpers/query";
import KindergartensCards from "@/components/info/kindergartens/KindergartensCards";
import KindergartensControllers from "@/components/info/kindergartens/KindergartensControllers";
import KindergartensUsers from "@/components/info/kindergartens/KindergartensUsers";
import KindergartensUsersGroup from "@/components/info/kindergartens/KindergartensUsersGroup";
import KindergartensPersonnel from "@/components/info/kindergartens/KindergartensPersonnel";
import ChildrenGroup from "@/components/info/kindergartens/KindergartensChildrenGroup";
import KindergartensChildren from "@/components/info/kindergartens/KindergartensChildren";
import KindergartensPassageLog from "@/components/info/kindergartens/KindergartensPassageLog";
import DeletePopup from "@/components/popup/DeletePopup";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import BlockPopup from "@/components/popup/BlockPopup";
import UnBlockPopup from "@/components/popup/UnBlockPopup";
import { patchOrganizationsByIdRequest } from "@/helpers/api/organizations";
import KindergartensPayments from "@/components/info/kindergartens/KindergartensPayments";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import phoneFilter from "@/helpers/filters/phoneFilter";
import BasicTableGeneralInfo from "@/components/tables/BasicTableGeneralInfo.vue";
export default {
  name: "KindergartensInfosPages",
  components: {
    BasicTableGeneralInfo,
    UnBlockPopup,
    BlockPopup,
    DeletePopup,
    KindergartensPassageLog,
    KindergartensChildren,
    ChildrenGroup,
    KindergartensPersonnel,
    BasicTableWithoutHeader,
    SlideLayout,
    MainLayout,
    InfoLayout,
    KindergartensCards,
    KindergartensControllers,
    KindergartensUsers,
    KindergartensUsersGroup,
    KindergartensPayments
  },
  data: () => ({
    queries: query.organizations,
    showRequisites: false,
    infoTabs: [
      {
        text: "Реквизиты",
        value: "recv"
      },
      {
        text: "Персонал",
        value: "admins"
      }
    ],
    headers: [
      // {
      //   value: "id",
      //   text: "№"
      // },
      {
        value: "id",
        text: "ID"
      },
      {
        renderFunction: value => {
          return `<div>${value.last_name || ""} ${value.first_name ||
            ""} ${value.middle_name || ""}</div>`;
        },
        text: "ФИО"
      },
      {
        text: "Телефон",
        renderFunction: value => phoneFilter.execute(value?.phone_number)
      }
    ]
  }),
  computed: {
    slideTabs() {
      return [
        {
          text: "Общая информация",
          value: "info",
          icon: "i"
        },
        {
          text: "Карты",
          value: "physical_cards",
          count: this.sourceObject.results?.cards_count,
          icon: null
        },
        {
          text: "Контроллеры",
          value: "controllers",
          count: this.sourceObject.results?.controllers_count,
          icon: null
        },
        // {
        //   text: "Группы пользователей",
        //   value: "users_group",
        //   count: this.sourceObject.results?.groups_count,
        //   icon: null
        // },
        {
          text: "Пользователи",
          value: "users",
          count: this.sourceObject.results?.users_count,
          icon: null
        },
        {
          text: "Детские группы",
          value: "childrens_groups",
          count: this.sourceObject.results?.children_groups_count,
          icon: null
        },
        {
          text: "Воспитанники",
          value: "childrens",
          count: this.sourceObject.results?.children_count,
          icon: null
        },
        {
          text: "Журнал проходов",
          value: "pass_log",
          icon: null,
          count: null
        },
        {
          text: "Платежи",
          value: "payments",
          icon: null
        }
      ];
    },
    sourceObject() {
      return this.$store.getters.getDealerInfo;
    },
    getDataAdmins() {
      return this.$store.getters.getAdmins;
    },
    getDataKindergartensRequisites() {
      return [
        {},
        {
          text: "Полное наименование",
          value: this.sourceObject.results
            ? this.sourceObject.results.full_org_name
            : ""
        },
        {
          text: "Краткое наименование",
          value: this.sourceObject.results ? this.sourceObject.results.name : ""
        },
        {
          text: "Тип",
          value:
            dictionariesHelper.organizationOwnership[
              this.sourceObject.results.ownership
            ]
        },
        {
          text: "Руководитель",
          value: this.sourceObject.results.director
        },
        {
          text: "Город",
          value: this.sourceObject.results.city_details.name
        },
        {
          text: "Адрес организации/фактический адрес",
          value: `${this.sourceObject.results.address} / ${this.sourceObject.results.actual_address}`
        },
        {
          text: "Телефон",
          value: phoneFilter.execute(this?.sourceObject?.results?.phone_number)
        },
        {
          text: "E-mail",
          value: this.sourceObject.results.email
        }
      ];
    },
    getDataRequisites() {
      if (
        this.sourceObject.results?.code_contractor &&
        this.sourceObject.results?.accept_code_contractor
      ) {
        return [
          {},

          // {
          //   text: "Дилер",
          //   value: this.sourceObject.results?.diller_details?.name
          // },
          {
            text: "Код контрагента",
            value: this.sourceObject.results?.code_contractor
          },
          {
            text: "ИНН",
            value: this.sourceObject.results.TIN
          },
          {
            text: "ОРГН",
            value: this.sourceObject.results.PSRN
          },
          {
            text: "БИК",
            value: this.sourceObject.results.RCBIC
          },
          {
            text: "КПП",
            value: this.sourceObject.results.IEC
          },
          {
            text: "Название банка",
            value: this.sourceObject.results.bank
          },
          {
            text: "К/c",
            value: this.sourceObject.results.CA
          },
          {
            text: "Р/c",
            value: this.sourceObject.results.giro
          }
        ];
      } else {
        return [
          {},
          {
            text: "ИНН",
            value: this.sourceObject.results.TIN
          },
          {
            text: "ОРГН",
            value: this.sourceObject.results.PSRN
          },
          {
            text: "БИК",
            value: this.sourceObject.results.RCBIC
          },
          {
            text: "КПП",
            value: this.sourceObject.results.IEC
          },
          {
            text: "Название банка",
            value: this.sourceObject.results.bank
          },
          {
            text: "К/c",
            value: this.sourceObject.results.CA
          },
          {
            text: "Р/c",
            value: this.sourceObject.results.giro
          }
        ];
      }
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  methods: {
    setKindergartenId() {
      localStorage.setItem("KindergartenId", this.urlQuery.id);
      localStorage.removeItem("ObjectId");
      localStorage.removeItem("ApartmentFieldId");
      localStorage.removeItem("CarNumberFieldId");
      localStorage.removeItem("HouseFieldId");
    },
    changeVisitlogControl(value) {
      patchOrganizationsByIdRequest({
        id: this.urlQuery.id,
        data: { allow_visitlog_control: value }
      });
    },
    deleteCompany() {
      this.$store
        .dispatch("deleteCompany", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.kindergarten)
          );
        });
    },
    blockKindergartens() {
      this.$store
        .dispatch("blockOrganization", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.ban(successMessageDictionary.kindergarten)
          );
        });
    },
    unBlockKindergartens() {
      this.$store
        .dispatch("unBlockOrganization", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.unBan(successMessageDictionary.kindergarten)
          );
        });
    }
  },
  created() {
    this.setKindergartenId();
    this.$store.commit("setKindergartensId", {
      id: this.$route.params.id
    });
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("getKindergartenInfoById").finally(() => {
      this.isDateLoaded = false;
    });
    this.$store.dispatch("fetchKinderInfo").finally(() => {
      this.isDateLoaded = false;
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchAdmins").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });

      this.isDateLoaded = true;
      // this.$store.dispatch("getKindergartenInfoById").finally(() => {
      //   this.isDateLoaded = false;
      // });
      // this.$store.dispatch("fetchKinderInfo").finally(() => {
      //   this.isDateLoaded = false;
      // });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchAdmins").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearOrganizationPage").then(() => next());
  }
};
</script>

<style scoped></style>
