import { render, staticRenderFns } from "./InfoLayout.vue?vue&type=template&id=662ed643&scoped=true&"
import script from "./InfoLayout.vue?vue&type=script&lang=js&"
export * from "./InfoLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "662ed643",
  null
  
)

export default component.exports