<template>
  <div class="">
    <v-dialog v-model="downloadPopup" max-width="570">
      <v-card class="pa-8">
        <div class="object__item">
          В отчёте больше 1000 записей, поэтому он сохранится в реестр выгрузок.
          Перейдите
          <router-link :to="{ name: 'Profile' }" exact
            >на страницу профиля</router-link
          >, чтобы скачать отчёт.
        </div>

        <v-card-actions class="mt-4 d-flex justify-end">
          <v-spacer></v-spacer>
          <v-btn class="btn btn_color_white" @click="downloadPopup = false">
            ОТМЕНА
          </v-btn>
          <v-btn
            :loading="downloadDialog"
            class="btn btn_color_green"
            @click="dowloadPeriodPassLog()"
          >
            ПРОДОЛЖИТЬ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="downloadDialog"
      hide-overlay
      persistent
      width="600"
      height="400"
    >
      <v-card color="#95c23d" dark>
        <v-card-text>
          <div class="title title-loading">
            Ожидайте выгрузки данных
          </div>

          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div>
      <!-- popup подтверждающий удаление -->
      <DeletePopupCommon
        v-if="deleteCkeckboxList.length > 1"
        title="карты"
        description="Вы уверены, что хотите удалить выбранный список карт?"
        :name="numCard"
        :dialog="dialog"
        @closeDeletePopup="closeDeletePopup"
        :delete-function="deleteFunction"
      />
      <DeletePopupCommon
        v-else
        title="карту"
        description="Вы уверены, что хотите удалить выбранную карту?"
        :name="numCard"
        :dialog="dialog"
        @closeDeletePopup="closeDeletePopup"
        :delete-function="deleteFunction"
      />
      <!-- /popup подтверждающий удаление-->

      <TableFilters :title-count="sourceObject.count" :queries="queries">
        <template v-slot:filter-title>
          <span v-if="sourceObject.count">
            <span class="title-filter">Всего:</span>{{ sourceObject.count }}
            <!--          <span>физических карт</span>-->
          </span>
        </template>
        <template v-slot:download-button>
          <v-btn
            @click="downloadList"
            class="btn btn_color_grey btn-unload flex-right mr-4"
            :disabled="loadingBtn || sourceObject.results.length == 0"
            :loading="loadingBtn"
          >
            Выгрузить
          </v-btn>
        </template>
        <template v-slot:add-button>
          <router-link
            to="createCardPage"
            append
            class="btn btn-add btn_color_green"
            >Добавить карту</router-link
          >
        </template>
        <template v-if="deleteCkeckboxList.length > 1" v-slot:delete-button>
          <v-btn
            :disabled="isDisabled"
            color="#EB5C6D"
            class="btn btn-delete btn_color_red"
            @click="clickDelete"
            style="color:white"
          >
            Удалить карты
          </v-btn>
        </template>
        <template v-else v-slot:delete-button>
          <v-btn
            :disabled="isDisabled"
            color="#EB5C6D"
            class="btn btn-delete btn_color_red"
            @click="clickDelete"
            style="color:white"
          >
            Удалить карту
          </v-btn>
        </template>
      </TableFilters>
      <TableLayout
        :loading="isDateLoaded"
        to="card"
        :table-data="sourceObject.results"
        :header="header"
        :source-object="sourceObject"
        delete-all
        dropDown
      >
        <template slot-scope="{ itemFather }">
          <v-checkbox
            class="ma-0 pa-0 checkbox-type"
            :value="itemFather.id"
            v-model="deleteCkeckboxList"
            hide-details
          ></v-checkbox>
        </template>
      </TableLayout>
    </div>
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import DeletePopupCommon from "@/components/popup/DeletePopupCommon";
import tableColsCards from "@/helpers/tableCols/cards";

import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "KindergartensCards",
  data() {
    return {
      loadingBtn: false,
      dialog: false, // диалоговое окно popup удаления
      numCard: "", // номер карты
      idCard: "", // id карты
      downloadPopup: false,
      downloadDialog: false,

      queries: query.cards.filter(
        el =>
          el.type !== "object__organization__diller" &&
          el.type !== "organization_type" &&
          el.type !== "object__organization" &&
          el.type !== "activation-period-start" &&
          el.type !== "activation-period-end" &&
          el.type !== "organizations"
      )
    };
  },
  components: { TableFilters, TableLayout, DeletePopupCommon },
  computed: {
    deleteCkeckboxList: {
      get() {
        return this.$store.getters.getDeleteList;
      },
      set(value) {
        this.$store.commit("UPDATE_DELETE_LIST", value);
        if (this.$store.getters.getCheckedAll.includes("all")) {
          this.$store.commit("SET_CHECKED_ALL", []);
        }
      }
    },
    header() {
      return tableColsCards.filter(
        el =>
          el.text !== "Детский сад" &&
          el.text !== "Дилер" &&
          el.text !== "ФИО" &&
          el.text !== "Телефон"
      );
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    sourceObject() {
      return this.$store.getters.getOrganizationCardsList;
    },
    sourceCreateObject() {
      let result =
        (this.$store?.getters?.getDealerInfo?.results?.created_at + 2592000) *
        1000;
      let now = Date.now();
      let res = now < result;

      return res;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    isDisabled() {
      return this.deleteCkeckboxList.length <= 0 ? true : false;
    }
  },
  methods: {
    deleteFunction() {
      let data = {
        cards_to_delete: this.deleteCkeckboxList
      };
      this.$store
        .dispatch("deleteArrCards", { data })
        .then(() => {
          this.isDateLoaded = true;
          this.$store.dispatch("fetchOrganizationCardsList").finally(() => {
            this.isDateLoaded = false;
          });
        })
        .then(() => {
          this.$store.dispatch("clearCheckedList");
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.cards)
          );
        });
    },

    clickDelete() {
      this.dialog = true;
    },

    closeDeletePopup(data) {
      this.dialog = data;
    },

    downloadList() {
      if (this.sourceObject.count > 1000) {
        this.downloadPopup = true;
      } else {
        this.loadingBtn = true;
        this.$store
          .dispatch("downloadOrganizationCardsList", this.header)
          .then(() => {
            this.loadingBtn = false;
          });
      }
    },
    dowloadPeriodPassLog() {
      this.downloadDialog = true;
      this.downloadPopup = false;
      this.$store
        .dispatch("fetchOrganizationObjectsList") // вызываем чтобы получить id объекта ДС
        .then(res => {
          const orgName = this.$store.getters.getDealerInfo.results?.name;

          this.objectId =
            res.results.filter(el => el?.name == orgName)[0]?.id ||
            res.results[0].id;
        })
        .then(() => {
          this.$store
            .dispatch("downloadReportsCardsList", this.objectId)
            .then(() => {
              setTimeout(() => (this.downloadDialog = false), 5000);
            });
        });
    }
  },
  created() {
    this.$store.dispatch("clearCheckedList");
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: { ...this.urlQuery.query },
      id: this.urlQuery.id
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchKindergartensCardsList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchKindergartensCardsList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  }
  // beforeRouteLeave(to, from, next) {
  //   this.$store.dispatch("clearCardsPage").then(() => next());
  // }
};
</script>

<style>
.checkbox-type .v-input__control .v-input__slot {
  height: 0px;
  top: 5px;
}
</style>
