<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries">
      <template v-slot:filter-title>
        <span v-if="sourceObject.count">
          <span class="title-filter">Всего:</span>{{ sourceObject.count }}
          <!--          <span>детских групп</span>-->
        </span>
      </template>
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
      <template v-slot:add-button>
        <router-link
          to="childrenGroupsCreate"
          append
          class="btn btn-add btn_color_green"
        >
          Добавить детскую группу
        </router-link>
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      to="childrenGroups"
      :header="header"
      :source-object="sourceObject"
      :table-data="tableData"
    />
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import TableFilters from "@/components/tables/TableFilters";
import * as query from "@/helpers/query";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";

export default {
  name: "KindergartensChildrenGroup",
  components: { TableFilters, TableLayout },
  data() {
    return {
      loadingBtn: false,
      queries: query.groupChildren,
      header: [
        {
          text: "Номер детской группы",
          value: "number"
        },
        {
          text: "Название детской группы",
          value: "name"
        },

        {
          text: "Группы пользователей",
          value: "group",
          renderFunction: value =>
            value.group_details ? value.group_details.title : ""
        },

        {
          text: "Стоимость",
          value: "price",
          renderFunction: value => {
            return value.appprices_details &&
              value?.appprices_details?.[0]?.duration == 3 &&
              value?.cardprices_details?.[0]
              ? `<div style="min-width: 250px"> Приложение:
  ${value?.appprices_details?.[0]?.duration} мес.=${value?.appprices_details?.[0]?.cost_with_discount}₽ | ${value?.appprices_details?.[1]?.duration} мес.=${value?.appprices_details?.[1]?.cost_with_discount}₽
  Карта:
  ${value?.cardprices_details?.[0]?.duration} мес.=${value?.cardprices_details?.[0]?.price}₽
            </div>`
              : value?.appprices_details?.[0]?.duration == 3
              ? `<div style="min-width: 250px"> ${value?.appprices_details?.[0]?.duration} мес.=${value?.appprices_details?.[0]?.cost_with_discount}₽ | ${value?.appprices_details?.[1]?.duration} мес.=${value?.appprices_details?.[1]?.cost_with_discount}₽
            </div>`
              : value?.cardprices_details?.[0]?.price
              ? `<div style="min-width: 250px">Приложение:</br> Бесплатно </br> Карта: </br>Бессрочно=${value?.cardprices_details?.[0]?.price}₽</div>`
              : `<div style="min-width: 250px">Приложение: Бесплатно</div>`;
          }
        },
        {
          text: "Контроллеры",
          value: "controllers",
          src: "/img/table_icons/tabbar__icon_5.svg",
          width: 20,
          renderFunction: value => {
            return value.controllers.length > 0
              ? value.controllers_details.map(el => el.name)?.join("\n")
              : "-";
          }
        },
        {
          text: "Воспитатели",
          renderFunction: value =>
            value?.teachers_details
              ?.map(
                el =>
                  (el.additional_fields.length != 0
                    ? el.additional_fields[0].value
                    : "-") +
                  " (" +
                  `${
                    dictionariesHelper.accountsStatus.userAuth[
                      el.is_phone_confirmed
                    ]
                  }` +
                  ")" +
                  "<br/>"
              )
              ?.join("\n")
        }
      ]
    };
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: {
        ...this.urlQuery.query,
        kindergarten_org: this.urlQuery.id,
        organization: this.urlQuery.id

        // type: this.type
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchChildrenGroupByKindergartens").finally(() => {});
    this.$store
      .dispatch("fetchOrganizationUserGroupListNoQuery")
      .finally(() => {
        this.isDateLoaded = false;
      });
  },
  computed: {
    sourceObject() {
      return this.$store.getters.getChildrenGroups;
    },
    sourceGroupObject() {
      return this.$store.getters.getOrganizationGroupList;
    },
    tableData() {
      let childrenGroups = this.sourceObject;
      let usersGroups = this.sourceGroupObject;
      let result = this.mergeObjects(childrenGroups, usersGroups);
      if (!result) result = [];
      return result;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  methods: {
    mergeObjects(obj1, obj2) {
      const merged = [];
      obj1.results.forEach(item1 => {
        obj2.results.forEach(item2 => {
          if (item1?.group === item2?.id) {
            delete item2?.id;
            const mergedItem = { ...item1, ...item2 };
            merged.push(mergedItem);
          }
        });
      });
      if (merged.length > 0) return merged;
    },

    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadKindergartensChildrenGroupsList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    }
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            kindergarten_org: this.urlQuery.id
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store
          .dispatch("fetchChildrenGroupByKindergartens")
          .finally(() => {});
        this.$store
          .dispatch("fetchOrganizationUserGroupListNoQuery")
          .finally(() => {
            this.isDateLoaded = false;
          });
      }
    }
  }
};
</script>

<style scoped></style>
