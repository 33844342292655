<template>
  <v-dialog v-model="dialog" persistent max-width="420">
    <template v-slot:activator="{ on, attrs }">
      <button
        v-bind="attrs"
        v-on="on"
        class="btn btn-delete btn-delete_block btn_color_green"
      >
        Разблокировать {{ name }}
      </button>
    </template>
    <v-card>
      <v-card-title class="delete-title delete-center">
        <div>Разблокировать</div>
        <span class="delete-bold">"{{ title }}" </span>
      </v-card-title>
      <v-card-actions>
        <v-btn
          width="154"
          height="42"
          color="#95c23d"
          class="ma-5 btn btn_color_white"
          text
          @click="close"
        >
          Отменить
        </v-btn>
        <v-spacer />
        <v-btn
          width="154"
          height="42"
          class="ma-5 btn btn-green"
          @click="realUnBlock"
        >
          Разблокировать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--  <div></div>-->
</template>

<script>
export default {
  name: "UnBlockPopup",
  data() {
    return {
      dialog: false
    };
  },
  props: {
    name: {
      type: [String, Number]
    },
    title: {
      type: String
    },
    unBlockFunction: {
      type: Function
    }
  },
  methods: {
    close() {
      this.dialog = false;
    },
    realUnBlock() {
      this.unBlockFunction();
      this.close();
    }
  }
};
</script>

<style scoped>
.delete-title {
  padding-top: 30px !important;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px !important;
  /* or 143% */

  text-align: center !important;

  color: #515a6e;
}
.delete-bold {
  font-weight: 700 !important;
}

.delete-center {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 12px;
}
</style>
