<template>
  <v-dialog v-model="dialog" persistent max-width="420">
    <template v-slot:activator="{ on, attrs }">
      <button
        v-bind="attrs"
        v-on="on"
        class="btn btn-delete btn-delete_block btn_color_red"
      >
        Заблокировать {{ name }}
      </button>
    </template>
    <v-card>
      <v-card-title class="delete-title delete-center">
        <div>Заблокировать</div>

        <div class="delete-bold">"{{ title }}"</div>
      </v-card-title>
      <v-card-actions>
        <v-btn
          width="154"
          height="42"
          color="#95c23d"
          class="ma-5 btn btn_color_white"
          text
          @click="close"
        >
          Отменить
        </v-btn>
        <v-spacer />
        <v-btn
          width="154"
          height="42"
          color="#EB5C6D"
          class="ma-5 btn btn_color_red"
          style="color:white"
          @click="realBlock"
        >
          Заблокировать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BlockPopup",
  data() {
    return {
      dialog: false
    };
  },
  props: {
    name: {
      type: [String, Number]
    },
    title: {
      type: String
    },
    blockFunction: {
      type: Function
    }
  },
  methods: {
    close() {
      this.dialog = false;
    },
    realBlock() {
      this.blockFunction();
      this.close();
    }
  }
};
</script>

<style scoped>
.delete-title {
  padding-top: 30px !important;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px !important;
  /* or 143% */

  text-align: center !important;

  color: #515a6e;
}
.delete-bold {
  margin-top: 12px;
  font-weight: 700 !important;
}

.delete-center {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 12px;
}
</style>
